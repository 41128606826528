<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9">
              <h2 class="mb-3">Description of Land</h2>
              <div class="mb-4 p-2 p-md-4 bg-medium">

                <!-- Add new PID form -->
                <form action="#" class="mb-5" v-if="addNewPidShown == true">
                  <div class="form-group">
                    <label for="">Enter PID or Related Plan Number</label>
                    <input class="form-control w-50" type="text" v-model="filingNumber">
                  </div>
                  <button class="btn btn-md btn-tertiary" @click.prevent="addRow(filingNumber)">Add to List</button>
                  <button class="btn btn-md btn-link" @click.prevent="addNewPidShown = false; showAddActions = true">Cancel</button>
                </form>

                <!-- Existing Related PIDs or plan numbers -->
                <div class="mb-5" v-if="existingPidsShown">
                  <p>PIDs or Plan Numbers already associated with your selected charges, liens, or interests</p>
                  <table class="mt-2 table table-stacked table-select-row bg-white">
                    <thead>
                      <tr>
                        <th class="text-nowrap" scope="col">PID/Plan Number</th>
                        <th scope="col">Legal Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!existingPidAdded">
                        <td scope="col" data-header="PID/Plan Number">
                          <span class="td-content">123-345-567</span>
                        </td>
                        <td scope="col" data-header="Legal Description">
                          <span class="td-content">LOT 5 DISTRICT LOT 261 GROUP 1 NEW WESTMINSTER DISTRICT PLAN LMP 31075</span>
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="5">There are no existing PIDs or Plan Numbers associated with your selected charges, liens, or interests</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <button class="btn btn-md btn-tertiary mr-3" @click.prevent="addExistingRow('123-345-567')" v-if="!existingPidAdded">
                      Add to List
                    </button>
                    <button class="btn btn-link pl-0" @click="existingPidsShown = false; showAddActions = true">Cancel</button>
                  </div>
                </div>

                <!-- Show table when there are existing PIDs -->
                <div class="d-lg-flex justify-content-between align-items-start mb-1" v-if="showAddActions">
                  <button class="btn btn-secondary btn-sm mr-auto" @click="existingPidsShown = true; showAddActions = false">
                    <svg class="icon icon-search"><use xlink:href="/icons/symbol-defs.svg#icon-search"></use></svg>
                  Add PIDs from Title Search
                  </button>
                  <div class="d-sm-flex mt-2 mt-lg-0">
                    <button class="btn btn-tertiary btn-sm mr-1 mb-1" @click="existingPidsShown = true; showAddActions = false">Find Related PIDs or Plan Numbers</button>
                    <button class="btn btn-tertiary btn-sm mb-1" @click="addNewPidShown = true; showAddActions = false; existingPidsShown = false;">+ Add New</button>
                  </div>
                </div>

                <!-- PID table -->
                <table class="table table-stacked table-stacked-col-full bg-white">
                  <thead>
                    <tr>
                      <th scope="col">PID/Plan Number</th>
                      <th scope="col" class="col-9">Legal Description</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Instead of the template element below, Angular should be able to use ng-repeat-start and ng-repeat-end for having two tr elements inside a loop -->
                    <template v-for="(item, index) in tableItems">
                      <tr :key="index">
                        <td data-header="Number">
                         <span class="td-content"> {{ item.a }}</span>
                        </td>
                        <td data-header="Legal Description">
                          <span class="td-content">

                            <!-- Description  -->
                            <template v-if="item.descriptionEdit == false">
                              <div class="pr-1">{{ item.b }}</div>  <a href="#" @click.prevent="edit(index)">Edit</a>
                            </template>
                            <div class="mb-2" v-if="item.descriptionEdit == true">
                              <div class="form-group mb-1">
                                <textarea class="form-control px-1 py-0" name="" id="" cols="30" rows="3" v-model="item.b"></textarea>
                              </div>
                              <button class="btn btn-sm btn-tertiary" @click="item.descriptionEdit = false">Save Changes</button>
                              <button class="btn btn-sm btn-link" @click="item.descriptionEdit = false">Cancel</button>
                            </div>

                            <!-- Undivided Interest -->
                            <div v-if="item.interestAdded == false">
                              <button class=" btn btn-link btn-sm pl-0 mt-1" href="#" @click.prevent="item.moreInfo = true" v-if="item.moreInfo == false && item.selected == true"><strong>Enter Undivided Interest</strong></button>

                              <form class="mt-1 mb-3" action="" v-if="item.moreInfo == true && item.selected == true">
                                <div class="flex-inline">
                                  <div class="form-group mb-1">
                                    <span class="text-nowrap">As to the undivided</span>
                                    <input class="form-control form-control-sm d-inline-block" style="width:2.5rem" name="interestNum" id="interestNum" type="text" v-model="item.interestNum">
                                    <span>/</span>
                                    <input class="form-control form-control-sm d-inline-block" style="width:2.5rem" name="interestNumOf" id="interestNumOf" type="text" v-model="item.interestNumOf">
                                    <span class="text-nowrap">
                                      Interest
                                      <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" class="text-tertiary" data-original-title="" title=""><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>
                                    </span>
                                  </div>
                                </div>

                                <button class="btn btn-sm btn-tertiary" @click.prevent="saveInfo(index)" type="submit">Done</button>
                                <button class="btn btn-sm btn-link" @click.prevent="item.moreInfo = false">Cancel</button>
                              </form>
                            </div>

                            <div class="mt-1" v-if="item.interestAdded == true && item.selected == true">
                              <p class="mb-0">As to the undivided <strong>{{ item.interestNum }}</strong> / <strong>{{ item.interestNumOf }}</strong> Interest <a class="pl-1" href="#" @click.prevent="item.interestAdded = false">Edit</a></p>
                            </div>

                            <!-- Order STC -->
                            <div class="mt-1">
                              <div class="custom-control custom-checkbox mb-1">
                                <input type="checkbox" class="custom-control-input" id="STC" v-model="item.STCOrdered">
                                <label class="custom-control-label" for="STC">Order STC</label>
                              </div>
                              <div class="ml-4" v-if="item.STCOrdered === true">
                                Delivery Method: {{ item.deliveryMethod }}
                                <a class="pl-1" href="#" @click.prevent="item.deliveryMethodEdited = !item.deliveryMethodEdited">Edit</a>
                              </div>
                            </div>

                            <div class="mt-1 ml-4" v-if="item.deliveryMethodEdited === true && item.STCOrdered === true">
                              <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="deliveryMethod1" name="deliveryMethod" value="Electronic" v-model="item.deliveryMethod" @click="item.deliveryMethodEdited = false">
                                <label class="custom-control-label" for="deliveryMethod1">Electronic Delivery</label>
                              </div>
                              <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="deliveryMethod2" name="deliveryMethod" value="Pick up at LTO" v-model="item.deliveryMethod">
                                <label class="custom-control-label" for="deliveryMethod2">Pick up at LTO</label>
                              </div>
                            </div>

                            <div class="mb-2 ml-4 mt-2" v-if="item.deliveryMethodEdited === true && item.deliveryMethod === 'Pick up at LTO' && item.STCOrdered === true">
                              <div class="form-group mb-1">
                                <label for="pickUpName">Enter the name of the firm or individual that will pick up the STC</label>
                                <input class="form-control" type="text" name="pickUpName" id="pickUpName" autocorrect="off">
                              </div>
                              <button class="btn btn-sm btn-tertiary" @click="item.deliveryMethodEdited = false">Done</button>
                              <button class="btn btn-sm btn-link" @click="item.deliveryMethodEdited = false">Cancel</button>
                            </div>
                          </span>
                        </td>
                        <td data-header="Actions">
                          <span class="td-content">
                            <a class="text-danger" href="#" @click.prevent="remove(index)">Remove</a>
                          </span>
                        </td>
                      </tr>
                    </template>

                    <tr v-if="filingAdded == false">
                      <td colspan="4">
                        <div class="d-flex">
                          <svg class="icon icon-lg icon-info text-tertiary"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
                          <p class="col">
                            You currently do not have any PIDs or Plan Numbers attached to this application. You can either manually add a new PID or Plan Number, or you can find PIDs or Plan Numbers already associated with your selected charges, liens, or interests.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      checked: 0,

      addNewPidShown: false,
      showAddActions: true,
      existingPid: false,
      existingPidAdded: false,
      existingPidsShown: false,
      affectedPids: false,
      moreInfoText: '',
      moreInformation: false,
      interestAdded: false,
      filingNumber: '',
      infoText: '',
      filingAdded: false,
      additionalFilingNumber: '',
      additionalFilingAdded: false,
      refreshAffectedPids: false,
      tableItems: []
    }
  },

  computed: {
    rowNum: function() {
      return this.tableItems.length;
    },
    checkedNum: function() {
      return filters.active(this.tableItems).length
    }
  },

  methods: {

    addExistingRow: function (input) {
      if (this.existingPid) {
        this.addRow(input)
        this.existingPidAdded = true
      }
    },

    addRow: function (input) {
      var value = input && input.trim()
      if (!value) {
        return
      }
      this.tableItems.splice(0, 0, {
        selected: true,
        moreInfo: false,
        STCOrdered: false,
        deliveryMethod: 'Electronic',
        deliveryMethodEdited: false,
        moreInterestNum: '',
        moreInterestNumOf: '',
        interestAdded: false,
        descriptionEdit: false,
        a: value,
        b: 'ELOT 4 DISTRICT LOT 261 GROUP 1 NEW WESTMINSTER DISTRICT PLAN LMP 31075',
      })
      this.filingNumber = ''
      this.filingAdded = true
      this.itemSelected = true
      this.additionalFilingAdded = true
      this.existingPidsShown = false
      this.addNewPidShown = false
      this.showAddActions = true
    },

    saveInfo: function(index) {
      this.tableItems[index].moreInterestNum = this.interestNum;
      this.tableItems[index].moreInterestNumOf = this.interestNumOf;
      this.tableItems[index].interestAdded = true;
    },

    remove: function(index) {
      this.tableItems.pop(index)

      if(this.tableItems.length == 0) {
        this.filingAdded = false
      }
    },

    edit: function(index) {
      this.tableItems[index].descriptionEdit = true;
    },

    checkbox: function(index) {
      this.tableItems[index].selected = this.selected;
    },

    loading: function() {
      this.refreshAffectedPids = true

      setTimeout(() => {
        this.refreshAffectedPids = false
      }, 2000)
    }
  }
}
</script>

